import styled from 'styled-components'

export const Container = styled.div`
  width: 300px;
  height: 140px;

  > button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    svg {
      margin-top: 8px;
      margin-bottom: 14px;
    }

    background: #fff;
    border: 2px solid #ddd;
    border-radius: 10px;
    padding: 12px;

    width: 300px;
    height: 140px;
  }
  &:hover {
    > button {
      border-color: #fff;
      box-shadow: 0px 0px 70px 1px #909090;
    }
  }
`
export const Button = styled.button`
  position: absolute;
  right: 12px;
  top: 12px;
  background: none;
  border: none;

  background-color: #ddd;
  border-radius: 50%;
  transition: 0.2s;
  padding: 10px 10px 8px 10px;
  &:hover {
    background-color: #dc3d4b;
  }
`

export const ModalContent = styled.p`
  margin-top: 42px;
  font-size: 18px;
  text-align: center;
`
