import React, { useEffect } from 'react'

import svgX from '../../../assets/svg/x.svg'
import { useToast } from '../../../hooks/useToast'

import { Container } from './styles'

export interface IToast {
  id: string
  type: 'success' | 'error' | 'info'
  tittle: string
  message: string
}

const Tooltip: React.FC<IToast> = ({ type, message, id, tittle }) => {
  const { deleteToast } = useToast()

  useEffect(() => {
    setTimeout(() => {
      deleteToast(id)
    }, 7000)
  }, [deleteToast, id])

  return (
    <Container type={type}>
      <button
        type="button"
        onClick={() => {
          deleteToast(id)
        }}
      >
        <img src={svgX} alt="close" />
      </button>
      <strong>{tittle}</strong>
      <p>{message}</p>
    </Container>
  )
}

export default Tooltip
