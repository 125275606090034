import React, { useEffect } from 'react'

import 'aos/dist/aos.css'

import AOS from 'aos'
import Layout from './pages/Layout'

const App: React.FC = () => {
  useEffect(() => {
    AOS.init({ duration: 900, easing: 'ease' })
  }, [])
  return <Layout />
}

export default App
