import styled, { css } from 'styled-components'
import { shade } from 'polished'

interface IButtonColors {
  color?: '#7EAC69' | '#679ACA' | '#303030'
  off?: boolean
  disabled?: boolean
}

export const Container = styled.button<IButtonColors>`
  font-size: 1.1em;
  border-radius: 5px;
  /* height: 56px; */
  border: 0;
  padding: 14px;
  color: #f2f2f2;
  width: 100%;
  max-width: 400px;
  font-weight: bold;
  transition: background-color 0.2s;

  background: ${props => props.color};
  &:hover {
    background: ${props => shade(0.2, `${props.color}`)};
  }
  ${props =>
    props.disabled &&
    css`
      background: #d0d0d0;
      color: #fff;
      cursor: default;
      &:hover {
        background: #d0d0d0;
        color: #fff;
      }
    `}
`
