import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  /* text-align: center; */
  justify-content: center;
`

export const Rules = styled.div`
  max-width: 800px;
  margin: 84px 12px 58px;
  background: #fff;
  border-radius: 5px;

  padding: 24px;

  position: relative;

  a {
    position: absolute;
    top: 12px;
    left: 12px;

    background: #eee;
    padding: 12px 13px 9px 12px;
    border-radius: 50%;

    transition: 0.2s;
    &:hover {
      background: #ddd;
    }
  }

  h1 {
    font-size: 24px;
    text-align: center;
    margin: 36px 24px;
  }
  p {
    text-align: justify;
    margin: 12px 0;
  }
  strong {
    margin: 42px 24px 24px;
  }

  ul {
    list-style-position: inside;
    margin: 10px 25px;
  }
  li {
    margin-bottom: 12px;
  }
  .space {
    margin-left: 56px;
  }

  box-shadow: 0px 0px 70px 1px #909090;

  @media (max-width: 421px) {
    margin: 84px 0 58px;
    padding: 12px;
  }
`
