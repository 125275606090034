import React from 'react'
import { AuthProvider } from './useAuth'
import { ToastProvider } from './useToast'

const AppProvider: React.FC = ({ children }) => {
  // import { Container } from './styles';

  return (
    <ToastProvider>
      <AuthProvider>{children}</AuthProvider>
    </ToastProvider>
  )
}

export default AppProvider
