import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import GlobalStyle from '../../styles/global'
import Routes from '../../Routes'

import AppProvider from '../../hooks'
import { Container } from './styles'

const Layout: React.FC = () => {
  return (
    <Router>
      <AppProvider>
        <Container>
          <Routes />
        </Container>
      </AppProvider>
      <GlobalStyle />
    </Router>
  )
}

export default Layout
