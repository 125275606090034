/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react'

import {
  FcDepartment,
  FcBusiness,
  FcManager,
  FcAlarmClock,
  FcBusinesswoman,
  FcSettings,
  FcGlobe,
  FcIdea,
  FcFaq,
} from 'react-icons/fc'
import { ImCheckmark } from 'react-icons/im'
import {
  GrGroup,
  GrHome,
  GrUserWorker,
  GrCatalog,
  GrCart,
  GrBriefcase,
  GrOrganization,
} from 'react-icons/gr'

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import AnimaBackground from '../../assets/anima_container_background.png'
import backgroundAnimaIcon from '../../assets/svg/backgroundAnimaIcon.svg'

import Card from '../../components/Card'
import CardModal from '../../components/CardModal'
import Button from '../../components/Button'
import Logo from '../../assets/logo.png'
import {
  Container,
  Anima,
  Cases,
  Advantages,
  HowWorks,
  SectionButtons,
  ComeToAnima,
  ComeToAnimaLeft,
  ComeToAnimaRight,
  Cards,
} from './styles'

const Home: React.FC = () => {
  const [howWorkVisible, setHowWorkVisible] = useState(false)
  return (
    <>
      <Container>
        <Header />
        <Anima id="anima">
          <div className="img_container">
            <img src={AnimaBackground} alt="" className="background" />
            <img src={backgroundAnimaIcon} alt="" className="background_top" />
            <img
              src={backgroundAnimaIcon}
              alt=""
              className="background_bottom"
            />
            <h1 data-aos="fade-in">INSPIRAÇÃO</h1>
            <p data-aos="fade-up">
              A ANIMA é a única plataforma que oferece uma rede MULTIDISCIPLINAR
              de profissionais prontos para auxiliar na construção COLABORATIVA
              da solução do conflito.
            </p>
            <p data-aos="fade-up">
              É também a única que proporciona a profundidade das SOLUÇÕES
              SISTÊMICAS para encontrar a raiz do problema ou a ALMA (ANIMA) da
              questão.
            </p>
            <p data-aos="fade-up">A partir daí a solução emerge.</p>
          </div>
          <div className="video-container">
            <iframe
              title="anima apresentação"
              src="https://www.youtube.com/embed/I5fOn0IAcSo"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Anima>
        <Cases id="casos">
          <h1 data-aos="fade-in">CASOS ATENDIDOS</h1>
          <div>
            <CardModal
              title="Familiar e Sucessões"
              modal="Contrato de namoro, união estável, regime de bens,
              divórcio, guarda, alimentos, alienação parental, divisão patrimonial,
              adoção, tutela, curatela, interdição, heranças, violência doméstica,
              adicções."
            >
              <GrGroup size={24} />
            </CardModal>
            <CardModal
              title="Contratos, Bens e Patrimônio"
              modal="Relações civis e comerciais, franquia, parceria, usufruto,
          arrendamento, fornecimento, perdas e danos, entre outros."
            >
              <GrHome size={24} />
            </CardModal>
            <CardModal
              title="Empresarial, Societário, Recuperação Judicial"
              modal="Com constelações organizacionais: sociedades, fusão, liquidação,
          marcas e patentes, propriedade, mercado, produtos e serviços,
          setores da empresa, cargos, liderança, sedes, filiais, dívidas."
            >
              <GrBriefcase size={24} />
            </CardModal>
            <CardModal
              title="Trabalhista"
              modal="Necessário um advogado de escritórios diferentes para cada parte."
            >
              <GrUserWorker size={24} />
            </CardModal>
            <CardModal
              title="Relações de Consumo"
              modal="Inadimplência, perdas, danos, capacidade de pagamento, falha de
          produto ou serviço, cobrança indevida; questões com bancos,
          financeiras, empresas aéreas, seguradoras, telefonia, internet,
          planos de saúde, eventos, entre outros."
            >
              <GrCart size={24} />
            </CardModal>
            <CardModal
              title="Condomínios, Vizinhança, Imobiliário"
              modal="
          Direito de uso, obras, aluguéis, inadimplência, indenizações,
          administração, síndico, questões de vizinhança, áreas comuns,
          regulamentação, renegociação de contratos."
            >
              <GrOrganization size={24} />
            </CardModal>
            <CardModal
              title="Instituições de Ensino"
              modal="
          Dívidas, direção, administração, professores, alunos, pais,
          comunidade; em casos como: indisciplina, vandalismo, violência,
          adicções, abuso sexual, preconceito, bullying."
            >
              <GrCatalog size={24} />
            </CardModal>
          </div>
        </Cases>
        <HowWorks alterConflict={howWorkVisible} id="funcionamento">
          <h1 data-aos="fade-in">COMO FUNCIONA</h1>
          <div className="alterVisible">
            <button
              type="button"
              className="button_client"
              onClick={() => setHowWorkVisible(false)}
            >
              Quero resolver um conflito
            </button>

            <button
              type="button"
              className="button_professional"
              onClick={() => setHowWorkVisible(true)}
            >
              Convidado
            </button>
          </div>
          <section className="client">
            <div>
              <span>1</span>
              <p>ENCONTRE O ESPECIALISTA</p>
            </div>
            <div>
              <span>2</span>
              <p>Agende seu atendimento individual se desejar ou</p>
            </div>
            <div>
              <span>3</span>
              <p>
                FAÇA UM CONVITE à outra parte indicando 3 profissionais para
                cada categoria desejada
              </p>
            </div>
            <div>
              <span>4</span>
              <p>Combine os detalhes com a outra parte no chat exclusivo</p>
            </div>
            <div>
              <span>5</span>
              <p>Agende o profissional</p>
            </div>
            <div>
              <span>6</span>
              <p>Pague o atendimento</p>
            </div>
            <div>
              <span>7</span>
              <p>Entre na reunião no dia e hora marcados</p>
            </div>
            <div>
              <span className="span_check">
                <ImCheckmark size={22} color="#14a76c" />
              </span>
              <p>
                Parabéns vocês encontraram a melhor solução! Se desejarem podem
                levar o acordo a registro ou homologação judicial.
              </p>
            </div>
          </section>
          <section className="profissional">
            <div>
              <span>1</span>
              <p>Tem 7 dias para entrar em contato</p>
            </div>
            <div>
              <span>2</span>
              <p>Clique em RECEBI UM CONVITE</p>
            </div>
            <div>
              <span>3</span>
              <p>Agende seu atendimento individual se desejar ou</p>
            </div>
            <div>
              <span>4</span>
              <p>
                No chat exclusivo aceite um dos profissionais indicados ou
                procure e indique outros 3
              </p>
            </div>
            <div>
              <span>5</span>
              <p>
                Realizem quantas reuniões e com todas as especialidades que
                forem necessárias
              </p>
            </div>
            <div>
              <span className="span_check">
                <ImCheckmark size={22} color="#14a76c" />
              </span>
              <p>
                Parabéns vocês encontraram a melhor solução! Se desejarem podem
                levar o acordo a registro ou homologação judicial.
              </p>
            </div>
          </section>
        </HowWorks>
        <Advantages id="vantagens">
          <h1 data-aos="fade-in">VANTAGENS</h1>
          <div className="advantages_div">
            <div>
              <FcFaq size={82} />
              <ul>
                <li>Foco no cuidado com as pessoas</li>
                <li>Mediação verdadeira</li>
                <li>Análise aprofundada e sistêmica do conflito</li>
              </ul>
            </div>
            <div>
              <FcAlarmClock size={82} />
              <ul>
                <li>Rapidez e economia</li>
                <li>Valor por hora, sem contratos</li>
              </ul>
            </div>
            <div>
              <FcBusinesswoman size={82} />
              <ul>
                <li>Escolha o melhor profissional para você</li>
                <li>Possibilidade de consultas individuais</li>
              </ul>
            </div>
            <div>
              <FcSettings size={82} />
              <ul>
                <li>Planos especiais para empresas e escritórios</li>
                <li>Sem burocracias, sem intermediários</li>
              </ul>
            </div>
            <div>
              <FcGlobe size={82} />
              <ul>
                <li>Acessível por celular</li>
                <li>Ambiente seguro e confiável</li>
              </ul>
            </div>
            <div>
              <FcIdea size={82} />
              <ul>
                <li>Sem taxas para cadastro</li>
                <li>Bonificação por indicação</li>
              </ul>
            </div>
          </div>
        </Advantages>
        <hr />
        <Cards id="especialidades">
          <h1 data-aos="fade-in">ESPECIALIDADES</h1>
          <Card header="Mediação Sistêmica (Postura e Pensamento Sistêmico)">
            MEDIAÇÃO - no qual os envolvidos num conflito se dispõem a, com a
            ajuda de um terceiro imparcial (mediador), encontrar maneiras de
            reestabelecer a comunicação rompida, conseguindo identificar e
            expressar seus sentimentos e necessidades, serem ouvidos e também
            acolher o outro,
            <br />
            <br />
            SISTÊMICA - através da ampliação da percepção proporcionada pelo
            olhar sistêmico, indo além da questão, deixando vir à tona as causas
            profundas de onde surgiu, que padrão seguiu e considerando tudo o
            que moveu os indivíduos até chegarem nesta situação, incluindo todo
            o sistema familiar, chegando a reconhecer sua parcela de
            responsabilidade na construção do conflito; para juntos construírem
            uma solução que seja a mais adequada e equilibrada para todos,
            aliviando os sistemas envolvidos e harmonizando o conjunto todo.
            <br />
            <br />
            Através desta moderna metodologia o Juiz Sami Storch já foi premiado
            pelo CNJ por ter alcançado o índice de 100% de acordos em sua
            Comarca na Bahia e hoje coordena o curso de Pós Graduação em Direito
            Sistêmico pela Hellinger Innovare no Brasil.
          </Card>
          <Card header="Mediação Transformativa ">
            Empoderar as pessoas em conflito, facilitando o diálogo, auxiliando
            cada uma das partes a compreender as necessidades e os interesses da
            outra, com o objetivo de restabelecer as relações e ao final
            resolver a disputa com consciência do caminho. O objetivo é mais do
            que resolver o conflito puramente, mas o promover reflexão e uma
            mudança comportamental dos envolvidos.
          </Card>
          <Card header="Mediação Tradicional-linear de Harvard">
            Chamada de mediação satisfativa ou facilitadora do diálogo e a
            negociação entre as partes, sem emitir opinião, oferecendo tempo e
            apoio para elas poderem chegar a um acordo sólido e duradouro. É um
            método para negociação colaborativa assistida por um terceiro. Foco
            no futuro em direção ao acordo baseado em seus interesses. Não
            objetiva tratar dos eventos passados nem das emoções.
          </Card>
          <Card header="Comunicação Não Violenta">
            A CNV coloca nossa atenção no que o outro está dizendo que não é
            expresso pelas palavras usadas. Entender que cada expressão, são
            tentativas de expressar valores e princípios compartilhados por
            todos nós. A CNV aprimora a capacidade de valorizar as nossas
            diferenças e de nos conectarmos naquilo que compartilhamos, focando
            em nossas semelhanças humanas.. É muito eficiente para auxiliar a
            pessoa num exercício de auto empatia e reconhecimento das suas
            próprias expectativas, frustrações e necessidades não atendidas e a
            melhor maneira de elaborar a comunicação para ser ouvida.
          </Card>
          <Card header="Constelação Sistêmica Familiar e Organizacional ">
            Na Constelação emergem as dinâmicas ocultas que são a raiz do
            conflito, muitas vezes causas antigas e profundas totalmente
            inconscientes onde ocorreram infrações às leis sistêmicas. Na grande
            maioria das vezes, senão em todas, a razão do conflito é totalmente
            diversa da que as partes e advogados supõem antes deste exercício de
            ampliação do olhar.
            <br />
            <br />
            As Constelações Sistêmicas, que nasceram Familiares, como uma nova
            abordagem de psicoterapia (e hoje são realizadas para consultorias
            organizacionais, sintomas de saúde, sistemas pedagógicos), também
            vieram auxiliar o sistema de justiça, nascendo assim o Direito
            Sistêmico. Pois, como a ciência das relações, elucida e revela tudo
            o que nos liga, o que nos afeta em relação aos demais integrantes de
            qualquer sistema ou grupo. Ensinadas por Bert Hellinger (indicado ao
            Nobel da Paz em 2011), com mais de 50 anos de trabalho com famílias
            e organizações ao redor do mundo, buscando o diagnóstico e solução
            dos problemas das mais diversas nuances, a partir dos fenômenos
            empiricamente observados, identificou que existia um pano de fundo
            comum para todos eles, o que reconheceu como sendo a infração à
            determinadas leis naturais que regem o equilíbrio dos sistemas que
            chamou de Ordens do Relacionamento Humano.
          </Card>
          <Card header="Psicólogos" noBody />
          <Card header="Peritos, Assistentes Técnicos">
            Contadores, auditores, engenheiros, avaliadores, tradutores,
            consultores, etc.
          </Card>
        </Cards>

        <SectionButtons id="cadastro">
          <h1 data-aos="fade-in">
            CADASTRE-SE AGORA MESMO PARA RECEBER OS PRÓXIMOS PASSOS ASSIM QUE A
            PLATAFORMA FOR LANÇADA
          </h1>
          <div>
            <div data-aos="fade-up">
              <FcManager size={124} />
              <p>Quero resolver um conflito</p>
              <a href="https://forms.gle/yS3Wmsmbo5RT2nQF6">
                <Button color="#7EAC69">SEJA CLIENTE</Button>
              </a>
            </div>
            <div data-aos="fade-up">
              <FcDepartment size={124} />
              <p>Planos especiais para grande demanda</p>
              <a href="https://forms.gle/DayjeNGyFZUR5QMu7">
                <Button color="#679ACA">EMPRESAS / ESCRITÓRIOS</Button>
              </a>
            </div>
            <div data-aos="fade-up">
              <FcBusiness size={124} />
              <p>Sou especialista, quero me cadastrar</p>
              <a href="https://forms.gle/8N331r4hDqtQJpWH8">
                <Button color="#303030">INTEGRE NOSSA EQUIPE</Button>
              </a>
            </div>
          </div>
        </SectionButtons>
        <ComeToAnima>
          <ComeToAnimaLeft data-aos="zoom-in-right">
            <div>
              <img src={Logo} alt="anima" />
              <h3>
                Quer resolver?
                <br />
                Vem pra anima!
              </h3>
            </div>
          </ComeToAnimaLeft>
          <ComeToAnimaRight data-aos="zoom-in-left">
            <p>
              Para VOCÊ pessoa física, empresário e advogado, que merece um
              atendimento profundo, sistêmico e eficiente, com conforto e
              facilidade de encontrar os maiores especialistas num só lugar:
              venha pra Anima para construirmos juntos as melhores soluções para
              suas questões jurídicas passíveis de autocomposição.
            </p>
          </ComeToAnimaRight>
        </ComeToAnima>
        <Footer />
      </Container>
      {/* <HelloMessage /> */}
    </>
  )
}

export default Home
