import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FiX } from 'react-icons/fi'

import heroBackground from '../../assets/hero-background.jpg'
import heroBackgroundMobile from '../../assets/hero-background-mobile.jpg'
import fullLogo from '../../assets/full-logo.png'
import * as S from './styles'

const Header: React.FC = () => {
  const [openMenuMobile, setOpenMenuMobile] = useState(false)

  return (
    <S.Container>
      <S.MenuMobile mobileMenuOpen={openMenuMobile}>
        <h1>
          <Link to="/" data-aos="fade-down">
            <img src={fullLogo} alt="anima intermediaçoes sistemicas" />
          </Link>
        </h1>
        <button
          type="button"
          onClick={() => setOpenMenuMobile(state => !state)}
        >
          <div />
          <div />
          <div />
        </button>
        <div>
          <button
            type="button"
            onClick={() => setOpenMenuMobile(state => !state)}
          >
            <FiX size={34} color="#303030" />
          </button>
          <a href="/#anima" onClick={() => setOpenMenuMobile(false)}>
            A ANIMA
          </a>
          <a href="/#casos" onClick={() => setOpenMenuMobile(false)}>
            CASOS ATENDIDOS
          </a>
          <a href="/#funcionamento" onClick={() => setOpenMenuMobile(false)}>
            COMO FUNCIONA
          </a>
          <a href="/#vantagens" onClick={() => setOpenMenuMobile(false)}>
            VANTAGENS
          </a>
          <a href="/#especialidades" onClick={() => setOpenMenuMobile(false)}>
            ESPECIALIDADES
          </a>
          <a href="/#cadastro" onClick={() => setOpenMenuMobile(false)}>
            CADASTRE-SE
          </a>

          <a href="/#contato" onClick={() => setOpenMenuMobile(false)}>
            CONTATO
          </a>
        </div>
      </S.MenuMobile>
      <S.MenuAndHero>
        <S.MenuWeb data-aos="fade-right">
          <a href="/#anima">A ANIMA</a>
          <a href="/#casos">CASOS ATENDIDOS</a>
          <a href="/#funcionamento">COMO FUNCIONA</a>
          <a href="/#vantagens">VANTAGENS</a>
          <a href="/#especialidades">ESPECIALIDADES</a>
          <a href="/#cadastro">CADASTRE-SE</a>
          <a href="/#contato">CONTATO</a>
        </S.MenuWeb>
        <S.Hero data-aos="fade-left">
          <img src={heroBackground} alt="" />
          <img
            src={heroBackgroundMobile}
            alt=""
            className="mobile-background"
          />

          <p className="title">
            Para VOCÊ, seu NEGÓCIO e seus CLIENTES,
            <br />
            os mais modernos meios de
            <br />
            SOLUÇÃO de CONFLITOS ON LINE
          </p>
        </S.Hero>
      </S.MenuAndHero>
    </S.Container>
  )
}

export default Header
