import { shade } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  z-index: 999999999;
  height: 100vh;
  width: 100vw;
  background: #ddd;

  display: flex;
  justify-content: center;
  align-items: center;

  div {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  h1.number {
    font-size: 200px;
    text-shadow: 2px 2px 8px #00000090;
    @media (max-width: 440px) {
      font-size: 134px;
    }
  }
  h1.letters {
    font-size: 42px;
    font-weight: lighter;
    position: relative;
    @media (max-width: 440px) {
      font-size: 24px;
    }
  }

  a {
    font-size: 36px;
    background: #fff;
    color: #7eac69;
    text-decoration: none;
    padding: 8px;

    margin-top: 12px;
    border-radius: 5px;

    transition: all 0.2s;
    &:hover {
      /* background: ${shade(0.2, '#fff')}; */
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.25);
    }
  }
`
