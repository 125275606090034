import React from 'react'
import { Link } from 'react-router-dom'

import { Container } from './styles'

const NotFound: React.FC = () => {
  return (
    <Container>
      <div>
        <h1 className="number">404</h1>
        <h1 className="letters">PAGE NOT FOUND</h1>
        <Link to="/">HOME</Link>
      </div>
    </Container>
  )
}

export default NotFound
