/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { keyframes, css } from 'styled-components'

interface MenuMobileProps {
  mobileMenuOpen: boolean
}

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;
`
export const MenuMobile = styled.div<MenuMobileProps>`
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;

  h1 {
    margin-left: 10%;
    width: 330px;
    margin-top: 3%;
    img {
      width: 95%;
    }
  }

  button {
    margin-left: auto;
    margin-right: 24px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    background: none;
    border: none;

    div {
      width: 36px;
      height: 6px;
      border-radius: 5px;
      background: #303030;
    }
  }
  > div {
    ${props =>
      props.mobileMenuOpen
        ? css`
            display: flex;
          `
        : css`
            display: none;
          `}
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: #f2f2f2;
    position: fixed;
    z-index: 99;
    top: 0;
    bottom: 0;
    width: 100%;
  }
  a {
    padding: 8px;
    text-decoration: none;
    color: #303030;
    font-size: 22px;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (min-width: 750px) {
    h1 {
      width: 480px;
    }
    > button {
      display: none;
    }
  }
  @media (max-width: 560px) {
    height: 34vh;
    h1 {
      width: 230px;
    }
  }
`
export const MenuAndHero = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-around; */
  align-items: start;

  height: 60vh;
  @media (max-width: 560px) {
    height: 66vh;
  }
`

export const MenuWeb = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 8px;
  margin-left: 10%;

  a {
    text-align: right;
    width: 100%;
    padding: 0 36px;
    text-decoration: none;
    color: #303030;
    font-size: 20px;
    animation: 0.2s;
    &:hover {
      text-decoration: underline;
    }
    & + a {
      margin-top: 12px;
    }
  }

  @media (max-width: 749px) {
    display: none;
  }
`

export const Hero = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: 10%;

  overflow: hidden;
  display: flex;
  flex: 1;
  height: 61vh;
  max-width: 750px;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  p {
    margin: auto auto 0 auto;
    padding: 12px;

    font-size: 24px;

    line-height: 32px;

    z-index: 1;
    background: #617a45a0;
    color: #fff;
    max-width: 600px;
    text-align: center;
  }

  .mobile-background {
    opacity: 0;
  }

  @media (max-width: 853px) {
    p {
    }
  }
  @media (max-width: 749px) {
    margin-left: 10%;
  }
  @media (max-width: 600px) {
    p {
      width: auto;
      font-size: 18px;
      line-height: 22px;
    }
  }
  @media (max-width: 560px) {
    height: 66vh;
  }
  @media (max-width: 499px) {
    margin: 0 8px;
    min-width: 271px;
    p {
      width: 271px;

      font-size: 14px;
    }
    .mobile-background {
      opacity: 1;
    }
  }
`
