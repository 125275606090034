import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from '../pages/Home'
import Polices from '../pages/Polices'
import NotFound from '../pages/NotFound'

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/politicadeuso" exact component={Polices} />
      <Route path="/*" component={NotFound} />
    </Switch>
  )
}

export default Routes
