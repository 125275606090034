/* eslint-disable @typescript-eslint/no-unused-vars */
import styled, { css } from 'styled-components'

interface HowWorksProps {
  alterConflict: boolean
}

export const Container = styled.div`
  overflow: hidden;
  display: block;

  hr {
    width: 90%;
    border: 1px solid #eee;
    margin: 0 auto;
  }
`

export const Anima = styled.div`
  padding-top: 54px;
  background: #fff;
  clear: both;
  display: flex;
  align-items: center;
  flex-direction: column;

  .img_container {
    width: 80%;
    position: relative;
    padding-bottom: 36px;

    > .background {
      object-fit: cover;
      width: 100%;
      height: 100%;
      z-index: 0;
      position: absolute;
    }
    .background_top,
    .background_bottom {
      position: absolute;
      width: 154px;
      height: 154px;
      opacity: 0.4;
    }

    .background_top {
      top: 0;
      left: 0;
    }
    .background_bottom {
      right: 0;
      bottom: 0;
      transform: rotate(180deg);
    }
  }

  h1 {
    text-align: center;
    color: #fff;
    margin-top: 48px;
    position: relative;
  }
  p {
    color: #fff;
    margin: 24px 24px 0;
    position: relative;
    font-size: 24px;
    padding: 16px 36px 16px;

    text-align: center;
  }
  .video-container {
    margin: 52px 0;
    overflow: hidden;
    position: relative;
    width: 80%;
  }

  .video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  .video-container iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 811px) {
    p {
      margin: 24px 0 0;
      padding: 8px 14px 8px;
    }
  }
  @media (max-width: 400px) {
    p {
      font-size: 20px;
    }
  }
`

export const Cases = styled.div`
  padding-top: 56px;
  background: #ddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    text-align: center;
  }
  div {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;

    flex-wrap: wrap;
    margin: 26px;
    button {
      font-size: 24px;
      display: inline-block;
    }
  }
`

export const Advantages = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */

  padding-bottom: 36px;
  h1 {
    margin-top: 36px;
    margin-bottom: 36px;
  }
  .advantages_div {
    max-width: 1125px;
    margin-top: 3px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    div {
      margin-top: 24px;
      width: 288px;
      display: flex;
      flex-direction: column;
      align-items: center;

      ul {
        list-style: none;
        /* list-style-position: inside; */
        text-align: center;
        li {
          font-size: 24px;
          line-height: 26px;
          margin-top: 12px;
        }
      }
    }
  }
`

export const HowWorks = styled.div<HowWorksProps>`
  background: #fff;
  padding-bottom: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h1 {
    margin: 34px 0;
  }
  button {
    font-size: 20px;
    z-index: 1;
    background: none;
    border: none;
    padding: 12px 24px 12px;
    margin: 0 24px;

    border-radius: 5px 5px 0 0;
  }
  > div {
    display: flex;
    flex-wrap: nowrap;
  }
  section {
    box-shadow: 0px 0px 10px 0px #979797;
    border-radius: 10px;
    padding: 24px;

    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    margin: 0 24px;
    div + div {
      margin-top: 12px;
      border-top: 2px solid #0001;
      padding-top: 12px;
    }
    div {
      text-align: left;
      display: flex;
      align-items: center;
      span {
        margin-right: 12px;
        display: flex;

        padding: 8px 14px;
        background: #fff;
        border: 4px solid #d0d0d0;
        border-radius: 50%;
        font-size: 18px;
        font-weight: bold;
      }
      .span_check {
        padding: 8px;
        background: #fff;
        border-color: #14a76c;
      }
    }
  }
  ${props =>
    props.alterConflict
      ? css`
          .client {
            display: none;
          }
          .button_professional {
            box-shadow: 0px -8px 8px -4px #979797;
            background: #f9f9f9;
          }
        `
      : css`
          .profissional {
            display: none;
          }
          .button_client {
            background: #f9f9f9;
            box-shadow: 0px -8px 8px -4px #979797;
          }
        `}
  @media(max-width:422px) {
    .button_client {
      margin-left: auto;
      font-size: 16px;
    }
    .button_professional {
      margin-right: auto;
      font-size: 16px;
    }
    section {
      margin: 0;
    }
  }
  @media (max-width: 535px) {
    .button_client {
      padding: 8px;
      margin-right: 0;
    }
    .button_professional {
      padding: 8px;
      margin-left: 0;
    }
  }
`

export const SectionButtons = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 100px 34px 230px;

  h1 {
    width: 100%;
    margin-bottom: 82px;
  }
  div {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    max-width: 1282px;
    align-items: center;
    justify-content: space-around;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 24px;
    }
    p {
      margin-top: 12px;
      color: #606060;
      font-weight: bold;
      font-size: 18px;
    }
  }
  button {
    width: 294px;
    margin: 12px;
  }
`

export const ComeToAnima = styled.div`
  display: flex;

  background: #fff;

  flex-wrap: wrap;
`

export const ComeToAnimaLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 24px;
  min-height: 350px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    color: #5e3e29;

    font-size: 22px;
    h3 {
      width: 192px;
    }

    img {
      width: 80px;
      margin-right: 12px;
    }
  }
`

export const ComeToAnimaRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 28px;
  background: #617a45;
  color: #f2f2f2;
  text-align: center;

  min-height: 350px;

  min-width: 50%;

  /* min-width: 300px; */
  p {
    max-width: 400px;
    font-size: 20px;
  }
`

export const Cards = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  background: #fff;
  padding: 52px 0;

  h1 {
    margin-bottom: 42px;
  }
`
