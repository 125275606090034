import styled, { css, keyframes } from 'styled-components'

interface TooltipProps {
  type: 'success' | 'error' | 'info'
}

const ToastApears = keyframes`
  0%{
    transform:translate(-50%);
    opacity:0;
  }100%{
    transform:translate(0);
    opacity:1;

  }
`

export const Container = styled.div<TooltipProps>`
  animation: ${ToastApears} 0.5s;
  position: relative;
  /* width: 300px; */
  max-width: 300px;
  margin-right: 16px;
  border-radius: 4px;
  padding: 12px;

  background: #fffa;

  ${props =>
    props.type === 'info' &&
    css`
      border-left: 4px solid #bddfff;
    `};
  ${props =>
    props.type === 'error' &&
    css`
      border-left: 4px solid #ff7575;
    `};
  ${props =>
    props.type === 'success' &&
    css`
      border-left: 4px solid #b6e3a2;
    `};

  strong {
    margin-right: 16px;
  }
  p {
    margin-top: 6px;
    font-size: 14px;
    color: #5e5e5e;
  }

  & + div {
    margin-top: 8px;
  }

  button {
    position: absolute;
    top: 0px;
    right: 0px;
    background: transparent;
    border: none;
    padding: 3px;

    border-radius: 5px;
    width: 18px;
    height: 18px;
    img {
      width: 100%;
      height: auto;
    }
  }

  -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`
