import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
html{

  scroll-behavior: smooth;
}
 *{
    margin:0;
    padding:0;
    box-sizing:border-box;
    outline:none;
    font-family:'Roboto', Helvetica, sans-serif
  }
  body{
    color:#303030;
    overflow-x:auto;
    -webkit-font-smoothing:antialiased;
    /* width: 100vw; */
  }
  button{
    cursor: pointer;
  }
`
