import React, { useState } from 'react'
import { FiX } from 'react-icons/fi'
import Modal from 'react-modal'
import * as S from './styles'

interface CardModalProps {
  title: string
  modal: string
}
Modal.setAppElement('#root')
const CardModal: React.FC<CardModalProps> = ({ title, modal, children }) => {
  const [open, setOpen] = useState(false)
  return (
    <S.Container data-aos="fade-in">
      <button type="button" onClick={() => setOpen(true)}>
        {children}
        <p>{title}</p>
      </button>

      <Modal
        data-aos="fade-in"
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        style={{
          content: {
            borderRadius: '10px',
            width: '330px',
            height: '320px',
            maxWidth: '80%',
            margin: 'auto',
            zIndex: 999,
          },
          overlay: {
            backgroundColor: '#00000040',
            zIndex: 998,
          },
        }}
      >
        <S.Button type="button" onClick={() => setOpen(false)}>
          <FiX size={24} color="#fff" />
        </S.Button>
        <S.ModalContent>{modal}</S.ModalContent>
      </Modal>
    </S.Container>
  )
}

export default CardModal
