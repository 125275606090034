import React, { ButtonHTMLAttributes } from 'react'
import { Container } from './styles'

type buttonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean
  color?: '#7EAC69' | '#679ACA' | '#303030'
  active?: boolean
}

const Input: React.FC<buttonProps> = ({
  children,
  loading,
  // active,
  color = '#7EAC69',
  ...rest
}) => {
  return (
    <Container
      type="button"
      // off={active}
      // disabled={active}
      {...rest}
      color={color}
    >
      {loading ? 'carregando...' : children}
    </Container>
  )
}

export default Input
