import React from 'react'
import { Link } from 'react-router-dom'
import { FaFacebook, FaYoutube } from 'react-icons/fa'
import { RiInstagramFill } from 'react-icons/ri'
import { MdMail } from 'react-icons/md'
import { Container, Polices, SocialMedia } from './styles'

const Footer: React.FC = () => {
  return (
    <Container id="contato">
      <SocialMedia>
        <div>
          <a
            href="https://www.facebook.com/animasolucoessistemicas/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook size={24} />
            Facebook
          </a>
          <a
            href="https://www.youtube.com/channel/UC7wTd6dRSZR0zF1hT5Uo_ag?view_as=subscriber"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube size={24} />
            Youtube
          </a>
          <a
            href="https://www.instagram.com/animasolucoessistemicas/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <RiInstagramFill size={24} />
            Instagram
          </a>
          <a href="mailto:animasistemica@gmail.com">
            <MdMail size={24} />
            Email
          </a>
        </div>
      </SocialMedia>
      <Polices>
        <Link to="/politicadeuso">Política de Privacidade</Link>
      </Polices>
    </Container>
  )
}

export default Footer
