import { shade } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  background: #f0f0f0;
  padding: 34px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 242px;
`

export const SocialMedia = styled.div`
  h2 {
    color: #303030;
    text-align: center;
    margin: 24px 12px;
  }
  div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  a {
    width: 120px;
    margin: 20px 30px;
    text-decoration: none;
    color: #617a45;
    font-size: 18px;

    display: flex;
    align-items: center;
    transition: color 0.2s ease;
    svg {
      margin-right: 5px;
    }
    &:hover {
      color: ${shade(0.2, '#617a45')};
    }
  }
`

export const Polices = styled.div`
  width: 60%;
  text-align: center;
  margin-top: 12px;
  padding-top: 24px;
  border-top: 2px solid #ccc;

  min-width: 232px;
  a {
    /* display: block; */
    text-align: center;
    color: #617a45;
    text-decoration: none;
    margin: 0 auto;
    width: 232px;
    padding: 8px;
    font-size: 17px;

    transition: color 0.2s;
    &:hover {
      color: #4285f4;
    }
  }
`
