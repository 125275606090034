import React, { createContext, useCallback, useContext, useState } from 'react'

import { uuid } from 'uuidv4'
import { IToast } from '../components/ToastContainer/Toast'
import ToastContainer from '../components/ToastContainer'

interface ProviderValue {
  createToast(information: Omit<IToast, 'id'>): void
  deleteToast(id: string): void
}

const ToastContext = createContext<ProviderValue>({} as ProviderValue)

export const ToastProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<IToast[]>([])

  const createToast = useCallback(
    ({ type, tittle, message }: Omit<IToast, 'id'>): void => {
      setToasts(state => [...state, { id: uuid(), type, tittle, message }])
    },
    [],
  )

  const deleteToast = useCallback((id: string) => {
    setToasts(state => state.filter(toast => toast.id !== id))
  }, [])

  return (
    <ToastContext.Provider value={{ createToast, deleteToast }}>
      {children}
      <ToastContainer toasts={toasts} />
    </ToastContext.Provider>
  )
}

export const useToast = (): ProviderValue => {
  const context = useContext(ToastContext)

  return context
}
