import React, { useState } from 'react'

import { Container, BodyCard, HeaderCard } from './styles'
import arrowDropdown from '../../assets/svg/arrow-dropdown.svg'

interface CardProps {
  children?: React.ReactNode
  header: string
  noBody?: boolean
}

const Card: React.FC<CardProps> = ({ children, header, noBody }) => {
  const [showCard, setShowCard] = useState(false)
  return (
    <Container>
      <HeaderCard
        show={showCard}
        onClick={() => {
          setShowCard(!showCard)
        }}
      >
        <h3>{header}</h3>
        {!noBody && (
          <div className="saiba_mais">
            <img src={arrowDropdown} alt="ver mais" />
          </div>
        )}
      </HeaderCard>
      {!noBody && (
        <BodyCard show={showCard}>
          <p>{children}</p>
        </BodyCard>
      )}
    </Container>
  )
}

export default Card
