import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import api from '../service/api'

interface Credentials {
  email: string
  password: string
}

interface IUser {
  id: string
  email: string
  name: string
  avatar_url: string
}

interface AuthState {
  token: string
  refreshToken: string
}
interface AuthContextData {
  user: IUser
  signIn(data: Credentials): Promise<void>
  signUp(): void
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext)

  return context
}

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IUser>(() => {
    const storagedUser = localStorage.getItem('@Anima:user')
    if (!storagedUser) {
      return {} as IUser
    }
    return JSON.parse(storagedUser)
  })
  const [authData, setAuthData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Anima:token')
    const refreshToken = localStorage.getItem('@Anima:refreshToken')
    if (token && refreshToken) {
      api.defaults.headers.authorization = `Bearer ${token}`
      return { token, refreshToken }
    }
    return {} as AuthState
  })
  useEffect(() => {
    async function loadUser() {
      if (authData.token) {
        const response = await api.get('/user')
        setUser(response.data.content)
        localStorage.setItem(
          '@Anima:user',
          JSON.stringify(response.data.content),
        )
      }
    }
    loadUser()
  }, [authData])

  const signIn = useCallback(async ({ email, password }: Credentials) => {
    const response = await api.post('/session/signin', { email, password })
    const { refreshToken, token } = response.data.content

    setAuthData({ token, refreshToken })
    localStorage.setItem('@Anima:token', token)
    localStorage.setItem('@Anima:refreshToken', refreshToken)

    api.defaults.headers.authorization = `Bearer ${token}`
  }, [])

  const signUp = useCallback(() => {
    setAuthData({} as AuthState)
    setUser({} as IUser)
    localStorage.removeItem('@Anima:token')
    localStorage.removeItem('@Anima:refreshToken')
    localStorage.removeItem('@Anima:user')
  }, [])

  return (
    <AuthContext.Provider value={{ user, signIn, signUp }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
