import React from 'react'

import { Container } from './styles'
import Toast, { IToast } from './Toast'

interface ContainerProps {
  toasts: IToast[]
}

const ToastContainer: React.FC<ContainerProps> = ({ toasts }) => {
  return (
    <Container>
      {/* <Toast
        id="1"
        type="success"
        tittle="Você está autenticado"
        message="Bem-vindo a nossa plataforma!"
      />
      <Toast
        id="1"
        type="error"
        tittle="Você está autenticado"
        message="Bem-vindo a nossa plataforma!"
      />
      <Toast
        id="1"
        type="info"
        tittle="Você está autenticado"
        message="Bem-vindo a nossa plataforma!"
      /> */}

      {toasts.map(({ id, type, tittle, message }) => (
        <Toast key={id} id={id} type={type} tittle={tittle} message={message} />
      ))}
    </Container>
  )
}

export default ToastContainer
