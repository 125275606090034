import styled, { css } from 'styled-components'

interface ICardProps {
  show: boolean
}

export const Container = styled.div`
  & + & {
    border-top: 1px solid #f6f6f6;
  }
  width: 100%;
`
export const HeaderCard = styled.div<ICardProps>`
  cursor: pointer;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;

  padding: 8px;

  overflow: hidden;
  .saiba_mais {
    display: flex;
    align-items: center;

    img {
      margin-left: 36px;
      width: 13px;
      margin-right: 24px;
      margin-top: 4px;
      transition: all 0.2s ease;
    }
  }

  h3 {
    font-size: 24px;
    color: #303030;
    margin: 14px 0 14px 8%;
  }
  ${({ show }) =>
    show &&
    css`
      img {
        transform: rotate(90deg);
      }
    `}
  &:hover {
    .saiba_mais {
      color: #1c88eb;
    }
  }
  @media (max-width: 433px) {
    h3 {
      margin: 14px 0 14px 14px;
      font-size: 20px;
    }
    img {
      margin-left: 0;
      margin-right: 18px;
      margin-top: 4px;
    }
  }
`
export const BodyCard = styled.div<ICardProps>`
  background: #fff;
  color: #303030;
  text-align: justify;
  /* transition: all 0.6s; */
  transition: max-height 0.6s ease;
  max-height: 0px;
  overflow: hidden;
  p {
    padding: 24px 52px;
    font-size: 20px;
  }
  ${({ show }) =>
    show &&
    css`
      transition: max-height 1s ease-in;
      max-height: 1450px;
    `};

  @media (max-width: 433px) {
    text-align: start;
    p {
      padding: 24px 12px;
    }
  }
`
