import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'
import { Container, Rules } from './styles'

const Polices: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Container id="top">
      <Rules>
        <Link to="/">
          <FiArrowLeft size={32} color="#303030" />
        </Link>
        <h1>TERMO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS</h1>
        <p>
          Este documento visa registrar a manifestação livre, informada e
          inequívoca pela qual o Titular concorda com o tratamento de seus dados
          pessoais para finalidade específica, em conformidade com a Lei nº
          13.709 – Lei Geral de Proteção de Dados Pessoais (LGPD).
        </p>
        <p>
          Ao declarar que concorda com o presente termo, o Titular consente que
          a empresa Anima Soluções Sistêmicas, e-mail animasistemica@gmail.com,
          doravante denominada Controlador, tome decisões referentes ao
          tratamento de seus dados pessoais, bem como realize o tratamento de
          seus dados pessoais, envolvendo operações como as que se referem a
          coleta, produção, recepção, classificação, utilização, acesso,
          reprodução, transmissão, distribuição, processamento, arquivamento,
          armazenamento, eliminação, avaliação ou controle da informação,
          modificação, comunicação, transferência, difusão ou extração.
        </p>
        <strong>1. DADOS PESSOAIS</strong>
        <p>
          O Controlador fica autorizado a tomar decisões referentes ao
          tratamento e a realizar o tratamento dos seguintes dados pessoais do
          Titular:
        </p>
        <strong>1.1 PARA CLIENTES</strong>
        <p className="space">
          Nome completo, CPF/CNPJ, telefone/whatsapp, e-mail e responsável (se
          CNPJ).
        </p>
        <strong>1.2 PARA EMPRESAS/ESCRITÓRIOS</strong>
        <p className="space">
          Nome empresarial, CNPJ, nome e cargo do responsável pelo contato,
          telefone/whatsapp, e-mail.
        </p>
        <strong>1.3 PROFISSIONAIS INTERESSADOS EM INTEGRAR NOSSA REDE</strong>
        <p className="space">
          Nome, CPF, categoria profissional, especialidades, telefone/whatsapp,
          e-mail.
        </p>
        <strong>2. FINALIDADES DO TRATAMENTO DE DADOS</strong>
        <p>
          O tratamento dos dados pessoais listados neste termo tem as seguintes
          finalidades:
        </p>
        <p>
          Possibilitar que o Controlador identifique e entre em contato com o
          Titular para fins de relacionamento comercial.
        </p>
        <p>
          Possibilitar que o Controlador envie ou forneça ao Titular seus
          produtos e serviços, de forma remunerada ou gratuita.
        </p>
        <strong>3. COMPARTILHAMENTO DE DADOS</strong>
        <p>
          O Controlador fica autorizado a compartilhar os dados pessoais do
          Titular com outros agentes de tratamento de dados, caso seja
          necessário para as finalidades listadas neste termo, observados os
          princípios e as garantias estabelecidas pela Lei geral de proteção de
          dados e demais legislações aplicáveis ao caso.
        </p>
        <strong>4. SEGURANÇA DOS DADOS</strong>
        <p>
          É de responsabilidade do controlador a manutenção de medidas de
          segurança e de governança aptas a proteger os dados pessoais de
          acessos não autorizados e de situações acidentais ou ilícitas de
          destruição, perda, alteração, comunicação ou qualquer forma de
          tratamento inadequado ou ilícito.
        </p>
        <p>
          Em conformidade ao art. 48 da Lei nº 13.709, o Controlador comunicará
          ao Titular e à Autoridade Nacional de Proteção de Dados (ANPD) a
          ocorrência de incidente de segurança que possa acarretar risco ou dano
          relevante ao Titular.
        </p>
        <strong>5. TERMO FINAL DO TRATAMENTO DE DADOS</strong>
        <p>
          O Controlador poderá manter e tratar os dados pessoais do Titular
          durante todo o período em que estes forem necessários ao alcance das
          finalidades listadas neste termo. Dados pessoais anonimizados, sem
          possibilidade de associação ao indivíduo, poderão ser mantidos por
          período indefinido.
        </p>
        <p>
          O termo final deste cadastro se dará no momento em que cada Titular
          efetue seu cadastro completo na plataforma oficial da Anima Soluções
          Sistêmicas quando esta estiver operando.
        </p>
        <p>
          O Titular poderá requerer via e-mail ao Controlador, a qualquer
          momento, que sejam eliminados os dados pessoais não anonimizados do
          Titular. O Titular fica ciente de que poderá ser inviável ao
          Controlador continuar o fornecimento de produtos ou serviços ao
          Titular a partir da eliminação dos dados pessoais.
        </p>
        <strong>6. DIREITOS DO TITULAR</strong>
        <p>
          O Titular tem direito a obter do Controlador, em relação aos dados por
          ele tratados, a qualquer momento e mediante requerimento:
        </p>
        <ul>
          <li> confirmação da existência de tratamento;</li>
          <li> acesso aos dados;</li>
          <li>correção/atualização de dados;</li>
          <li>
            anonimização, bloqueio ou eliminação de dados desnecessários,
            excessivos ou tratados em desconformidade com o disposto na Lei nº
            13.709;
          </li>
          <li>
            portabilidade dos dados a outro fornecedor de serviço ou produto,
            mediante requerimento expresso e observadas as limitações técnicas e
            regulamentares;
          </li>
          <li>
            eliminação dos dados pessoais tratados com o consentimento do
            titular, exceto nas hipóteses previstas em lei;
          </li>
          <li>
            revogação do consentimento, nos termos do § 5º do art. 8º da Lei nº
            13.709.
          </li>
        </ul>
        <strong>7. DIREITO DE REVOGAÇÃO DO CONSENTIMENTO</strong>
        <p>
          Este consentimento poderá ser revogado pelo Titular, a qualquer
          momento, mediante solicitação via e-mail ao Controlador.
        </p>
      </Rules>
    </Container>
  )
}

export default Polices
